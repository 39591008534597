import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

/**
 * 查询项目集合
 * @param {*} param
 * @returns
 */
export const getProjectList = (param) =>
  getRequest(`/project-manage/project/getList`, param);
/**
 * 查询单个项目
 * @param {项目id} id
 * @returns
 */
export const getProject = (id) => getRequest(`/project-manage/project/${id}`);
/**
 * 新建项目
 * @param {*} param
 * @returns
 */
export const createProject = (param) =>
  postRequest(`/project-manage/project/create`, param);
/**
 * 删除项目
 * @param {*} param
 * @returns
 */
export const deleteProject = (param) =>
  deleteRequest(`/project-manage/project/${param}`);
/**
 * 修改项目
 * @param {*} param
 * @returns
 */
export const updateProject = (param) =>
  updateRequest(`/project-manage/project/update`, param);
/**
 * 获取项目看板数据
 * @param {*} param
 * @returns
 */
export const getDashBoard = (param) =>
  getRequest("/project-manage/project/getDashBoard", param);
/**
 * 获取计划列表
 * @param {*} param
 * @returns
 */
export const getPlanList = (param) =>
  getRequest("/project-manage/plan/getList", param);
/**
 * 创建计划
 * @param {*} param
 * @returns
 */
export const createPlan = (param) =>
  postRequest("/project-manage/plan/create", param);
/**
 * 更新计划
 * @param {*} param
 * @returns
 */
export const updatePlan = (param) =>
  updateRequest("/project-manage/plan/update", param);
/**
 * 删除计划
 * @param {*} param
 * @returns
 */
export const deletePlan = (param) =>
  deleteRequest(`/project-manage/plan/${param}`);

/**
 * 关闭项目
 * @param {项目id} param
 * @returns
 */
export const closeProject = (param) =>
  getRequest("/project-manage/project/close", param);
/**
 * 发布项目
 * @param {*} param
 * @returns
 */
export const publicProject = (param) =>
  getRequest("/project-manage/project/public", param);

/**
 * 获取项目成员
 * @param {*} param
 * @returns
 */
export const getMembers = (param) =>
  getRequest(`/project-manage/project/getMembers`, param);
/**
 * 生成项目模板
 * @param {*} param
 * @returns
 */
export const createTemplate = (param) =>
  getRequest("/project-manage/project/createTemplate", param);
/**
 * 获取项目任务总览
 * @param {*} param
 * @returns
 */
export const getTaskOverView = (param) =>
  getRequest("/project-manage/project/getTaskOverView", param);

/** 项目模板生成项目 */
export const templateCreateProject = (parm) =>
  postRequest(`/common/projecttemplate/createproject`, parm);

/**获取项目文件 */
export const getProjectDocumentsAsync = (params) =>
  getRequest(`/project-manage/project/document/list`, params);
