<template>
  <div class="bgStyle">
    <a-page-header
      title="项目管理"
      :ghost="false"
      style="margin-left: 0px; padding-left: 0px"
    >
      <template #extra>
        <a-button key="1" type="primary" @click="add">创建项目</a-button>
      </template>
    </a-page-header>
    <div>
      <a-space :size="24">
        <a-select v-model="status" style="width: 180px" ref="select">
          <a-select-option value="0">全部项目</a-select-option>
          <a-select-option value="1">我负责的项目</a-select-option>
          <a-select-option value="2">我参与的项目</a-select-option>
        </a-select>

        <a-input placeholder="项目名称" v-model="name" style="width: 180px" />
        <a-button type="primary" @click="getList">搜索</a-button>
      </a-space>
    </div>
    <a-divider />
    <div class="gutter">
      <a-spin :spinning="loading">
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="6" v-for="p in list" :key="p.id">
            <div class="gutter-box">
              <a-card
                :title="p.name"
                style="overflow: hidden"
                :headStyle="cardStyle"
                hoverable
                @click="edit(p.id)"
              >
                <template #extra>
                  <div class="ondoing" v-if="p.statusName == '进行中'">
                    <span>{{ p.statusName }}</span>
                  </div>
                  <div class="delay" v-if="p.statusName == '已逾期'">
                    <span>{{ p.statusName }}</span>
                  </div>
                  <div class="unallocated" v-if="p.statusName == '待发布'">
                    <span>{{ p.statusName }}</span>
                  </div>
                  <div class="done" v-if="p.statusName == '已关闭'">
                    <span>{{ p.statusName }}</span>
                  </div>
                </template>
                <p class="companyName" :title="companyName(p.tenantId)">
                  所属公司：{{ companyName(p.tenantId) }}
                </p>
                <p class="ownerName" :title="p.ownerName">责任人：{{ p.ownerName }}</p>
                <p class="date">
                  {{ p.startDate | dateFmt }}-{{ p.endDate | dateFmt }}
                  <a-icon
                    type="delete"
                    class="del"
                    @click.stop="deleteProject(p.id)"
                    v-if="userInfo.id == p.ownerId || userInfo.isCompanyAdmin"
                  />
                </p>
              </a-card>
            </div>
          </a-col>
          <a-empty v-if="list.length == 0" />
        </a-row>
      </a-spin>
    </div>
    <a-row type="flex">
      <a-col :span="24">
        <a-pagination
          style="float: right"
          :current="current"
          :page-size-options="pageSizeOptions"
          :total="total"
          show-size-changer
          :page-size="pageSize"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== '40'">{{ props.value }}条/页</span>
            <span v-else>全部</span>
          </template>
        </a-pagination>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getProjectList, deleteProject } from "@/api/projectmanage";
import moment from "moment";
export default {
  name: "ProjectManageIndex",
  components: {},
  data() {
    return {
      name: "",
      status: "0",
      pageSizeOptions: ["1", "8", "16", "24", "32", "40"],
      current: 1,
      pageSize: 8,
      loading: false,
      total: 50,
      list: [],
      cardStyle: {
        "border-bottom": "none",
        "font-size": "18px",
        "font-weight": "bold",
        "line-height": "24px",
      },
      userInfo: {},
    };
  },
  filters: {
    dateFmt: function (date) {
      if (date != null) return moment(date).format("YYYY/MM/DD");
    },
  },
  computed: {
    companyName() {
      return function (id) {
        let companys = this.userInfo.companyList;
        for (let c of companys) {
          if (c.tenantId == id) {
            return c.name;
          }
        }
        return "";
      };
    },
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo;
  },
  mounted() {
    this.getList();
  },
  methods: {
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getList();
    },
    onChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.getList();
    },
    add() {
      this.$router.push("/projectManage/add");
    },
    /**
     * 获取项目列表
     * @param {obj} parm [查询参数]
     */
    getList() {
      let param = {
        SkipCount: (this.current - 1) * this.pageSize,
        MaxResultCount: this.pageSize,
        Filter: this.name,
        ProjectOwner: this.status,
      };
      this.loading = true;
      getProjectList(param).then((res) => {
        this.loading = false;
        this.total = res.totalCount;
        this.list = res.items;
      });
    },
    edit(id) {
      this.$router.push("/projectManage/edit/" + id + "/1");
    },
    deleteProject(id) {
      let _this = this;
      this.$confirm({
        title: "确定",
        content: "删除当前项目",
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          _this.loading = true;
          deleteProject(id).then(() => {
            _this.loading = false;
            _this.$message.success("项目删除成功");

            _this.current = 1;
            _this.getList();
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gutter :deep(.ant-row > div) {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: transparent;
  padding: 15px 0;

  .ondoing {
    width: 80px;
    height: 80px;
    position: absolute;
    background: #1890ff;
    top: -40px;
    right: -40px;
    transform: rotate(45deg);
  }
  .unallocated {
    width: 80px;
    height: 80px;
    position: absolute;
    background: rgb(255, 153, 0);
    top: -40px;
    right: -40px;
    transform: rotate(45deg);
  }
  .done {
    width: 80px;
    height: 80px;
    position: absolute;
    background: rgb(153, 153, 153);
    top: -40px;
    right: -40px;
    transform: rotate(45deg);
  }
  .delay {
    width: 80px;
    height: 80px;
    position: absolute;
    background: rgb(255, 0, 51);
    top: -40px;
    right: -40px;
    transform: rotate(45deg);
  }
  p {
    line-height: 2.1em;
  }

  .ondoing,
  .unallocated,
  .done,
  .delay {
    span {
      position: absolute;
      bottom: 0;
      display: block;
      width: 80px;
      line-height: 20px;
      color: white;
      text-align: center;
    }
  }
  .del {
    float: right;
    font-size: 24px;
    display: none;
  }

  .ant-card:hover .del{
    display: block;
  }

  .companyName{
    color: #000000;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .ownerName{
    color: #000000;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .date{
    color: #999999
  }

  .ant-card-hoverable:hover{
    box-shadow: 5px 5px 10px #d3d3d3;
  }
}


</style>
